import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import List from "components/list";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/core-i5"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/corei5.png"}) { ...eyecatchImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    allCoreIJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Core i5 比較表 | 世代や用途ごとにCore i5の性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Nov 16 2024 00:00:25 GMT+0900 (日本標準時)">更新日:2024年11月16日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Core i5 比較" mdxType="Image" />
    <p>{`Core i5(新世代のCore Ultra 5も含む)はCore i3とCore i7の中間に位置する性能を持つミドルエンドCPUであり、最も売れ筋でかつ安定的なパフォーマンスを期待できるCPUである。`}</p>
    <p>{`ゲームや動画編集を含めた重めの作業も難なくこなす実力があるためコストパフォーマンスに優れたメインPCとして活用される事が多い。`}</p>
    <p>{`しかし他のCoreシリーズと同様に世代や用途の違いにより性能が大きく異なり、Core i5を購入したのに使えなかったというリスクも高い。本記事及び性能比較表にて本当に自分が選ぶCore i5が妥当なのか判断していこう。`}</p>
    <p>{`尚、Coreシリーズについての概要を知りたい人は`}<Link to="/cpu/core-i/" mdxType="Link">{`Coreシリーズ比較表`}</Link>{`へ、またAMDのCPU RyzenやCeleronなど全てのCPUとの比較は本家`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`で確認して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Core-i5の用途別性能差",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i5%E3%81%AE%E7%94%A8%E9%80%94%E5%88%A5%E6%80%A7%E8%83%BD%E5%B7%AE",
        "aria-label": "Core i5の用途別性能差 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i5の用途別性能差`}</h2>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`基本的にCPUは消費電力が大きいほど性能が高くなる。Core i5ではデスクトップ向け125Wのオーバークロック版からCPUからノートの10Wを切る超消費電力版まで幅広いラインナップがされている。`}</p>
    <p>{`その差は非常に大きくCore i3でも言えることだが、`}<strong parentName="p">{`低消費電力のCore i5のYシリーズは5世代前、すなわち５年前に発売された通常版のCore i5に敵わない`}</strong>{`というベンチマーク結果も出ている。`}</p>
    <p>{`末尾の文字に意識を払いつつCore i5 CPUを選択していくことが非常に重要である。`}</p>
    <h2 {...{
      "id": "Core-i5の一般的特徴と誤解",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i5%E3%81%AE%E4%B8%80%E8%88%AC%E7%9A%84%E7%89%B9%E5%BE%B4%E3%81%A8%E8%AA%A4%E8%A7%A3",
        "aria-label": "Core i5の一般的特徴と誤解 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i5の一般的特徴と誤解`}</h2>
    <p>{`用途別の性能が一般的に理解されておらず、`}<strong parentName="p"><em parentName="strong">{`Core i5は安定的な速度が出せるためノートパソコンでも安心と考える事はナンセンス`}</em></strong>{`である。`}</p>
    <p>{`低消費電力のYシリーズでは、ファンレス、長時間バッテリーのPCが期待できるが、Zoomで人数が増えるとカクカクしたり、`}<strong parentName="p">{`そもそも動作が非常に遅く安定しない`}</strong>{`。`}</p>
    <p>{`一方で最新世代のCore i5はデスクトップの事務用途で使うにはオーバースペック気味で、コストパフォーマンスが高いとはいえ`}<strong parentName="p">{`性能を使いきれないほど強いベンチマークスコアをはじき出している`}</strong>{`。`}</p>
    <p>{`用途を決め、ベンチマークスコアを確認し、場合によっては世代を一世代前にして価格を抑えるなど、機動的なパソコン購入戦略が必要となるかもしれない。`}</p>
    <h2 {...{
      "id": "Core-i5とCore-i7の違いは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i5%E3%81%A8Core-i7%E3%81%AE%E9%81%95%E3%81%84%E3%81%AF%EF%BC%9F",
        "aria-label": "Core i5とCore i7の違いは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i5とCore i7の違いは？`}</h2>
    <p>{`CPUの末尾文字が変わると性能差が大きく変わるので、違いを一概に答えることはできないが傾向としては`}</p>
    <List mdxType="List">
  <li>最新世代のCore i5が１世代前のCore i7に匹敵することもある。</li>
  <li>ベンチマークのスコアを価格で割ったコストパフォーマンスではCore i5に軍配が上がる。</li>
    </List>
    <p>{`ぐらいか。`}<strong parentName="p"><em parentName="strong">{`同世代、同用途であればCore i5よりCore i7の方が高速だが、Core i3とCore i5の方がその差は顕著`}</em></strong>{`ということは言えそうある。ただし、基本的には性能比較表で判断すべきである。`}</p>
    <h2 {...{
      "id": "Intel-Core-i5-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core-i5-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Intel Core i5 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Core i5 性能比較表`}</h2>
    <p>{`それではCore i5 CPUの様々な世代、用途における性能比較表を示す。Core i3、Core i7や他のCPUと比較したい場合はチェックボックスを利用して欲しい。`}</p>
    <p>{`ベンチマークとして総合的な能力を計測するPassMarkの総合スコアPassMark(M)とシングルスレッドの性能を測るPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば良い`}</em></strong>{`だろう。ただ、PASSMARKの値はベンチマークバージョンにより上下するため、他サイトのスコアは参考にならないので注意しておこう。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`その他、表の項目説明等はまとめておくので必要に応じて参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <Accordion title="Intel CPU末尾文字の意味一覧" mdxType="Accordion">
      <IntelMatsubi mdxType="IntelMatsubi" />
    </Accordion>
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'notedesk']} cpuData={props.data.allCoreIJson.nodes} checkStatus={{
      corefive: true
    }} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      